(function ($) {
	// Fix Background
	var scrollpos;
	$(".c-nav__btn").click(function () {
		if ($(this).hasClass("open")) {
			$("body").removeClass("fixed").css({ top: 0 });
			window.scrollTo(0, scrollpos);
			$(".c-nav__btn.open").removeClass("open");
			$(".c-nav__main__wrap.open").removeClass("open");
		} else {
			scrollpos = $(window).scrollTop();
			$("body").addClass("fixed").css({ top: -scrollpos });
			$(".c-nav__btn").addClass("open");
			$(".c-nav__main__wrap").addClass("open");
		}
	});
	$(".c-nav__main a").click(function () {
		$("body").removeClass("fixed").css({ top: 0 });
		window.scrollTo(0, scrollpos);
		$(".c-nav__btn.open").removeClass("open");
		$(".c-nav__main__wrap.open").removeClass("open");
	});

	// Scroll Event
	$(window).scroll(function () {
		if ($(window).scrollTop() > 100) {
			$("header").addClass("on");
		} else {
			$("header").removeClass("on");
		}
	});

	// Current Page
	$(".c-nav__main a").each(function () {
		if (this.href == location.href) {
			$(this).parent().addClass("current");
		} else {
			$(this).parent().removeClass("current");
		}
	});

	$(".c-nav__sub__list a").each(function () {
		if (this.href == location.href) {
			$(this).parent().addClass("current");
		} else {
			$(this).parent().removeClass("current");
		}
	});

	// Page top
	$(function () {
		$(".c-pagetop").click(function () {
			//#js-pagetopがクリックされたら、以下の処理を実行
			$("html,body").animate({ scrollTop: 0 }, "300");
		});
	});

	// Smooth Scroll
	$(function () {
		$("a").click(function () {
			var adjust = 0;
			var speed = 400; // ミリ秒
			var href = $(this).attr("href");
			var target = $(href == "#" || href == "" ? "html" : href);
			var position = target.offset().top + adjust;
			$("body,html").animate({ scrollTop: position }, speed, "swing");
			return false;
		});
	});

	// Mouse over Image
	$("a img").hover(
		function () {
			$(this).attr("src", $(this).attr("src").replace("_off", "_on"));
		},
		function () {
			if (!$(this).hasClass("currentPage")) {
				$(this).attr("src", $(this).attr("src").replace("_on", "_off"));
			}
		}
	);

	// disabled Links
	$("a.js-disable").click(function () {
		return false;
	});

	// touchmove handler
	var linkTouchStart = function () {
		thisAnchor = $(this);
		touchPos = thisAnchor.offset().top;
		moveCheck = function () {
			nowPos = thisAnchor.offset().top;
			if (touchPos == nowPos) {
				thisAnchor.addClass("hover");
			}
		};
		setTimeout(moveCheck, 100);
	};
	var linkTouchEnd = function () {
		thisAnchor = $(this);
		hoverRemove = function () {
			thisAnchor.removeClass("hover");
		};
		setTimeout(hoverRemove, 500);
	};
	$(document).on("touchstart mousedown", "a", linkTouchStart);
	$(document).on("touchend mouseup", "a", linkTouchEnd);
})(jQuery);
