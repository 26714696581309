(function ($) {
	// Accordion list
	$(".c-accordion__head").click(function () {
		$(this).next(".c-accordion__inner").slideToggle();
		$(this).toggleClass("open");
	});
	$("a.c-accordion__closebtn").click(function () {
		$(this).parents(".c-accordion__inner").slideUp();
		$(this).parents(".c-accordion__inner").prev().toggleClass("open");
	});

	$(".c-accordion__closebtn").click(function () {
		var adjust = 0;
		var speed = 500;
		var href = $(this).attr("href");
		var target = $(href == "#" || href == "" ? "html" : href);
		var position = target.offset().top - adjust;
		$(".c-accordion__head").removeClass("open");
		$("body,html").animate({ scrollTop: position }, speed, "swing");
		return false;
	});
})(jQuery);
